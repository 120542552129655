import React, { Component } from 'react';
import { Box, Card, CardContent, Grid, Button, IconButton } from '@mui/material'
import Typography from '@mui/material/Typography';
import { LoginOutlined } from '@mui/icons-material';

import './Fonts.css'
import './Home.css'

export class Home extends Component {
  static displayName = Home.name;

  render () {
    return (
      <>
        <Grid container className='cardContainer'>
          <Grid item lg={12} sx={{marginBottom: "20vh;"}}>
                    <div class="clickableTitle" onClick={() => { window.location.href = 'https://propel.ap-lynparza.com'}}>
              <Box sx={{ minWidth: 150 }}>
                <Card variant="outlined" className="aPCard">
                  <CardContent>
                      <Grid container spacing={0} class='titleGrid'>
                          <Grid item xs={11}>
                            <h1 class='aPTitle'>
                              Accès précoce post-AMM
                            </h1>
                          </Grid>
                          <Grid item xs={1}>
                            <LoginOutlined className='loginIcon'/>
                          </Grid>
                      </Grid>
                                    {/*<h2 class='aPSubtitle'>En monothérapie</h2>*/}
                  </CardContent>
                  <CardContent>
                    <p class='aPDescription'>Cancer de la prostate</p>
                  </CardContent>
                  <CardContent>
                    <Grid container spacing={8} className='cardActions'>
                      <Grid item xs={4} className='cardAction'>
                        <Button variant='outlined' size="large" onClick={(event) => {window.open('/documents/AP_LYNPARZA_PROpel-PUT-RD.pdf', '_blank'); event.stopPropagation();}} className='buttonAction'>PUT-RD</Button>
                      </Grid>
                      <Grid item xs={4} className='cardAction'>
                        <Button variant='outlined' size="large" onClick={(event) => {window.open('/documents/AP_LYNPARZA_PROpel-RCP.pdf', '_blank'); event.stopPropagation();}} className='buttonAction'>RCP</Button>
                      </Grid>
                      <Grid item xs={4} className='cardAction'>
                        <Button variant='outlined' size="large" onClick={(event) => {window.open('/documents/AP_LYNPARZA_PROpel-Documents_dinformation_a_destination_des_patients.pdf', '_blank'); event.stopPropagation();}} className='buttonAction buttonNormalCase'>Note d'Information</Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </div>
          </Grid>
        </Grid>

        
        
      </>
    );
  }
}
