import React, { Component } from 'react';
import { Home } from './components/Home';
import { Header } from './components/Header'
import { Footer } from './components/Footer'
import { Container } from '@mui/material/'

import './components/Fonts.css'
import './custom.css'

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <>
          <div class="pageContainer">
            <Header />
            <div class="contentWrap">
              <Container style={{maxWidth:"95%"}}>
                <Home />
              </Container>
            </div>
            <Footer />
          </div>
      </>
    );
  }
}
